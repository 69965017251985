const dashboard = {
    page_title: 'لوحة القيادة',
    title: 'لوحة القيادة',
    description: 'احصل على نظرة عامة عن أداء تطبيقك',
    total_users: 'إجمالي المستخدمين',
    total_users_tip: 'إجمالي المستخدمين',
    new_users_today: 'المستخدمون الجدد اليوم',
    new_users_today_tip: 'عدد المستخدمين الجدد المسجلين في تطبيقك اليوم',
    new_users_7_days: 'المستخدمون الجدد خلال 7 أيام',
    new_users_7_days_tip: 'عدد المستخدمين الجدد المسجلين في تطبيقك خلال الأيام السبعة الماضية',
    daily_active_users: 'المستخدمون النشطون يوميًا',
    daily_active_users_tip: 'عدد المستخدمين الفريدين الذين قاموا بتبادل الرموز في تطبيقك اليوم',
    weekly_active_users: 'المستخدمون النشطون أسبوعيًا',
    weekly_active_users_tip: 'عدد المستخدمين الفريدين الذين قاموا بتبادل الرموز في تطبيقك خلال الأسبوع الماضي',
    monthly_active_users: 'المستخدمون النشطون شهريًا',
    monthly_active_users_tip: 'عدد المستخدمين الفريدين الذين قاموا بتبادل الرموز في تطبيقك خلال الشهر الماضي',
};
export default Object.freeze(dashboard);
