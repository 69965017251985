const organization_details = {
    page_title: 'تفاصيل المؤسسة',
    delete_confirmation: 'بمجرد الحذف ، سيفقد جميع الأعضاء عضويتهم وأدوارهم في هذه المؤسسة. لا يمكن التراجع عن هذا الإجراء.',
    organization_id: 'معرف المؤسسة',
    settings_description: 'تمثل المؤسسات الفرق والعملاء التجاريين وشركات الشركاء التي يمكنها الوصول إلى تطبيقاتك.',
    name_placeholder: 'اسم المؤسسة ، ليس مطلوبًا أن يكون فريدًا.',
    description_placeholder: 'وصف المؤسسة.',
    member: 'عضو',
    member_other: 'أعضاء',
    add_members_to_organization: 'إضافة أعضاء إلى المؤسسة {{name}}',
    add_members_to_organization_description: 'ابحث عن المستخدمين المناسبين عن طريق البحث بالاسم أو البريد الإلكتروني أو الهاتف أو معرف المستخدم. لا يتم عرض الأعضاء الحاليين في نتائج البحث.',
    add_with_organization_role: 'إضافة بأدوار المؤسسة',
    user: 'مستخدم',
    application: 'تطبيق',
    application_other: 'تطبيقات',
    add_applications_to_organization: 'إضافة تطبيقات إلى المؤسسة {{name}}',
    add_applications_to_organization_description: 'ابحث عن التطبيقات المناسبة عن طريق البحث بمعرف التطبيق أو الاسم أو الوصف. لا يتم عرض التطبيقات الحالية في نتائج البحث.',
    at_least_one_application: 'مطلوب تطبيق واحد على الأقل.',
    remove_application_from_organization: 'إزالة التطبيق من المؤسسة',
    remove_application_from_organization_description: 'بمجرد إزالته ، سيفقد التطبيق ارتباطه وأدواره في هذه المؤسسة. لا يمكن التراجع عن هذا الإجراء.',
    search_application_placeholder: 'البحث بواسطة معرف التطبيق أو الاسم أو الوصف',
    roles: 'أدوار المؤسسة',
    authorize_to_roles: 'السماح لـ {{name}} بالوصول إلى الأدوار التالية:',
    edit_organization_roles: 'تحرير أدوار المؤسسة',
    edit_organization_roles_title: 'تحرير أدوار المؤسسة لـ {{name}}',
    remove_user_from_organization: 'إزالة المستخدم من المؤسسة',
    remove_user_from_organization_description: 'بمجرد إزالته ، سيفقد المستخدم عضويته وأدواره في هذه المؤسسة. لا يمكن التراجع عن هذا الإجراء.',
    search_user_placeholder: 'البحث بالاسم أو البريد الإلكتروني أو الهاتف أو معرف المستخدم',
    at_least_one_user: 'مطلوب مستخدم واحد على الأقل.',
    organization_roles_tooltip: 'الأدوار المعينة لـ {{type}} داخل هذه المؤسسة.',
    custom_data: 'بيانات مخصصة',
    custom_data_tip: 'البيانات المخصصة هي كائن JSON يمكن استخدامه لتخزين بيانات إضافية مرتبطة بالمؤسسة.',
    invalid_json_object: 'كائن JSON غير صالح.',
    branding: {
        logo: 'شعارات المؤسسة',
        logo_tooltip: 'يمكنك تمرير معرف المؤسسة لعرض هذا الشعار في تجربة تسجيل الدخول ؛ الإصدار الداكن من الشعار مطلوب إذا تم تمكين الوضع الداكن في إعدادات تجربة تسجيل الدخول الشاملة. <a>تعلم المزيد</a>',
    },
    jit: {
        title: 'التوفير في اللحظة الأخيرة',
        description: 'يمكن للمستخدمين الانضمام تلقائيًا إلى المؤسسة وتعيينهم أدوارًا عند تسجيلهم الأول من خلال بعض طرق المصادقة. يمكنك تعيين متطلبات لتلبية التوفير في اللحظة الأخيرة.',
        email_domain: 'توفير نطاق البريد الإلكتروني',
        email_domain_description: 'سينضم المستخدمون الجدد الذين يسجلون الاشتراك باستخدام عناوين البريد الإلكتروني المتحققة أو من خلال تسجيل الدخول الاجتماعي باستخدام عناوين البريد الإلكتروني المتحققة تلقائيًا إلى المؤسسة. <a>تعلم المزيد</a>',
        email_domain_placeholder: 'أدخل نطاقات البريد الإلكتروني للتوفير في اللحظة الأخيرة',
        invalid_domain: 'نطاق غير صالح',
        domain_already_added: 'تمت إضافة النطاق بالفعل',
        sso_enabled_domain_warning: 'لقد أدخلت واحدًا أو أكثر من نطاقات البريد الإلكتروني المرتبطة بـ SSO المؤسسة. سيتبع المستخدمون الذين لديهم هذه البريد الإلكتروني تدفق SSO القياسي ولن يتم توفيرهم لهذه المؤسسة ما لم يتم تكوين توفير SSO المؤسسة.',
        enterprise_sso: 'توفير SSO المؤسسة',
        no_enterprise_connector_set: 'لم تقم بإعداد أي موصل SSO المؤسسة بعد. أضف الموصلات أولاً لتمكين توفير SSO المؤسسة. <a>إعداد</a>',
        add_enterprise_connector: 'إضافة موصل المؤسسة',
        enterprise_sso_description: 'سينضم المستخدمون الجدد أو المستخدمون الحاليون الذين يسجلون الدخول من خلال SSO المؤسسة للمرة الأولى تلقائيًا إلى المؤسسة. <a>تعلم المزيد</a>',
        organization_roles: 'أدوار المؤسسة الافتراضية',
        organization_roles_description: 'تعيين الأدوار للمستخدمين عند الانضمام إلى المؤسسة من خلال التوفير في اللحظة الأخيرة.',
    },
    mfa: {
        title: 'المصادقة ذات العوامل المتعددة (MFA)',
        tip: 'عندما يتطلب MFA ، سيتم رفض المستخدمين الذين ليس لديهم MFA مكون عند محاولة تبادل رمز المؤسسة. لا يؤثر هذا الإعداد على مصادقة المستخدم.',
        description: 'يتطلب من المستخدمين تكوين المصادقة ذات العوامل المتعددة للوصول إلى هذه المؤسسة.',
        no_mfa_warning: 'لم يتم تمكين أي طرق مصادقة ذات عوامل متعددة للمستأجر الخاص بك. لن يتمكن المستخدمون من الوصول إلى هذه المؤسسة حتى يتم تمكين طريقة واحدة على الأقل <a>طريقة مصادقة ذات عوامل متعددة</a>.',
    },
};
export default Object.freeze(organization_details);
