const get_started = {
    page_title: 'البدء',
    title: 'شيء لمساعدتك على النجاح',
    subtitle: 'بعض الأشياء التي يمكنك القيام بها للحصول بسرعة على قيمة Logto',
    develop: {
        title: 'التطوير: قم بدمج تطبيقك في 5 دقائق',
        title_cloud: 'التطوير: قم بدمج تطبيقك بأمان في دقائق',
        subtitle_cloud: 'أو قم بدمج تطبيقك في 5 دقائق باستخدام SDKs والبرامج التعليمية الجاهزة لدينا.',
    },
    customize: {
        title: 'تخصيص: قدم تجربة تسجيل دخول رائعة',
        preview: {
            title: 'تحقق من المعاينة المباشرة لتجربة تسجيل الدخول التي قمت بتخصيصها للتو',
            subtitle: 'جرب تجربة تسجيل الدخول الخاصة بـ Logto الآن لمعرفة كيف يعمل',
        },
        connector: {
            title: 'إضافة المزيد من الموصلات لدعم طرق تسجيل الدخول الاجتماعي الأخرى',
            subtitle: 'جرب تسجيل الدخول بدون كلمة مرور وقم بتمكين تجربة آمنة وسلسة لعملائك',
        },
        continue_customizing: 'متابعة التخصيص',
        try_now: 'جرب الآن',
        add_more: 'إضافة المزيد',
    },
    secure: {
        title: 'تأمين: حماية مواردك',
    },
    manage: {
        title: 'إدارة: تعريف التحكم في الوصول لمنتجك والمستخدمين',
        rbac: {
            title: 'إضافة التحكم في الوصول بناءً على الأدوار لحماية مواردك',
            subtitle: 'تحكم في مواردك من خلال تفويض الأدوار المقياسي لحالات الاستخدام المتنوعة.',
        },
        create_roles: 'إنشاء الأدوار',
    },
    view_all: 'عرض الكل →',
};
export default Object.freeze(get_started);
