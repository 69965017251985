const general = {
    placeholder: 'نص العنصر',
    submit: 'إرسال',
    skip: 'تخطي',
    next: 'التالي',
    back: 'السابق',
    retry: 'حاول مرة أخرى',
    done: 'تم',
    search: 'بحث',
    search_placeholder: 'بحث',
    clear_result: 'مسح النتائج',
    save: 'حفظ',
    save_changes: 'حفظ التغييرات',
    saved: 'تم الحفظ',
    discard: 'تجاهل',
    loading: 'جاري التحميل...',
    redirecting: 'جاري التوجيه...',
    add: 'إضافة',
    added: 'تمت الإضافة',
    cancel: 'إلغاء',
    confirm: 'تأكيد',
    check_out: 'الدفع',
    create: 'إنشاء',
    set_up: 'إعداد',
    customize: 'تخصيص',
    enable: 'تمكين',
    reminder: 'تذكير',
    edit: 'تعديل',
    delete: 'حذف',
    deleted: 'تم الحذف',
    more_options: 'المزيد من الخيارات',
    close: 'إغلاق',
    copy: 'نسخ',
    copying: 'جاري النسخ',
    copied: 'تم النسخ',
    required: 'مطلوب',
    add_another: 'إضافة آخر',
    create_another: 'إنشاء آخر',
    deletion_confirmation: 'هل أنت متأكد أنك تريد حذف {{title}} هذا؟',
    settings_nav: 'الإعدادات',
    unsaved_changes_warning: 'لقد قمت بإجراء بعض التغييرات. هل أنت متأكد أنك تريد مغادرة هذه الصفحة؟',
    leave_page: 'مغادرة الصفحة',
    stay_on_page: 'البقاء على الصفحة',
    type_to_search: 'اكتب للبحث',
    got_it: 'فهمت',
    continue: 'متابعة',
    page_info: '{{min, number}}-{{max, number}} من {{total, number}}',
    learn_more: 'معرفة المزيد',
    /** UNTRANSLATED */
    tab_error_one: '{{count, number}} error',
    /** UNTRANSLATED */
    tab_error_other: '{{count, number}} errors',
    skip_for_now: 'تخطي للآن',
    remove: 'إزالة',
    visit: 'زيارة',
    join: 'انضمام',
    try_now: 'جرب الآن',
    multiple_form_field: '(متعدد)',
    demo: 'عرض',
    unnamed: 'بدون اسم',
    view: 'عرض',
    open: 'فتح',
    hide: 'إخفاء',
    unknown_error: 'خطأ غير معروف، يرجى المحاولة مرة أخرى لاحقًا.',
    select: 'اختيار',
    contact_us_action: 'اتصل بنا',
    description: 'الوصف',
    name: 'الاسم',
    add_field: 'إضافة {{field}}',
    create_field: 'إنشاء {{field}}',
    edit_field: 'تعديل {{field}}',
    delete_field: 'حذف {{field}}',
    coming_soon: 'قريبًا',
    or: 'أو',
};
export default Object.freeze(general);
