const users = {
    page_title: 'إدارة المستخدمين',
    title: 'إدارة المستخدمين',
    subtitle: 'إدارة هويات المستخدمين بما في ذلك إنشاء المستخدمين، تعديل معلومات المستخدم، عرض سجلات المستخدم، إعادة تعيين كلمة المرور وحذف المستخدمين',
    create: 'إضافة مستخدم',
    create_subtitle: 'يرجى تقديم واحدة على الأقل من الحقول التالية للمتابعة.',
    error_missing_identifier: 'يجب تقديم معرف واحد على الأقل لإنشاء مستخدم.',
    user_name: 'المستخدم',
    application_name: 'من التطبيق',
    latest_sign_in: 'آخر تسجيل دخول',
    create_form_username: 'اسم المستخدم',
    create_form_password: 'كلمة المرور',
    create_form_name: 'الاسم الكامل',
    placeholder_name: 'جون/جين دو',
    placeholder_email: 'jdoe@example.com',
    placeholder_username: 'user123',
    placeholder_phone: '+1 (555) 555-5555',
    unnamed: 'بدون اسم',
    search: 'البحث بالاسم أو البريد الإلكتروني أو الهاتف أو اسم المستخدم',
    check_user_detail: 'تحقق من تفاصيل المستخدم',
    placeholder_title: 'إدارة المستخدمين',
    placeholder_description: 'يحتوي كل مستخدم على ملف تعريف يحتوي على جميع معلومات المستخدم. يتكون من البيانات الأساسية وهويات التواصل الاجتماعي والبيانات المخصصة.',
};
export default Object.freeze(users);
