const contact = {
    title: 'احصل على مساعدة',
    description: 'لطلب المساعدة أو إرسال ملاحظات حول المنتج، يرجى التواصل معنا عبر الوسائل التالية.',
    discord: {
        title: 'انضم إلى مجتمع Discord',
        description: 'تواصل مع المطورين الآخرين لإيجاد حلول',
        button: 'انضم',
    },
    github: {
        title: 'التواصل على GitHub',
        description: 'إنشاء مشكلة وإرسالها على GitHub',
        button: 'افتح',
    },
    email: {
        title: 'الاتصال بالدعم عبر البريد الإلكتروني',
        description: 'أرسل لنا بريدًا إلكترونيًا لمزيد من المعلومات والمساعدة',
        button: 'أرسل',
    },
    reserve: {
        title: 'احجز وقتك مع فريق Logto',
        description: 'احجز جلسة للدردشة المباشرة بسرعة',
        button: 'احجز',
    },
};
export default Object.freeze(contact);
