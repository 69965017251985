const log_details = {
    page_title: 'تفاصيل سجل التدقيق',
    back_to_logs: 'العودة إلى سجلات التدقيق',
    back_to: 'العودة إلى {{name}}',
    success: 'نجاح',
    failed: 'فشل',
    event_key: 'مفتاح الحدث',
    application: 'التطبيق',
    ip_address: 'عنوان IP',
    user: 'المستخدم',
    log_id: 'معرف السجل',
    time: 'الوقت',
    user_agent: 'وكيل المستخدم',
    tab_details: 'التفاصيل',
    raw_data: 'البيانات الخام',
};
export default Object.freeze(log_details);
