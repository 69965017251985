const logs = {
    page_title: 'سجلات التدقيق',
    title: 'سجلات التدقيق',
    subtitle: 'عرض بيانات السجل لأحداث المصادقة التي قام بها مستخدموك',
    event: 'حدث',
    user: 'مستخدم',
    application: 'تطبيق',
    time: 'الوقت',
    filter_by: 'تصفية حسب',
};
export default Object.freeze(logs);
