const roles = {
    page_title: 'الأدوار',
    title: 'الأدوار',
    subtitle: 'تتضمن الأدوار الأذونات التي تحدد ما يمكن للمستخدم القيام به. يستخدم RBAC الأدوار لمنح المستخدمين الوصول إلى الموارد لإجراءات محددة.',
    create: 'إنشاء دور',
    role_name: 'اسم الدور',
    role_type: 'نوع الدور',
    type_user: 'مستخدم',
    type_machine_to_machine: 'من الجهاز إلى الجهاز',
    role_description: 'الوصف',
    role_name_placeholder: 'أدخل اسم الدور الخاص بك',
    role_description_placeholder: 'أدخل وصف الدور الخاص بك',
    col_roles: 'الأدوار',
    col_type: 'النوع',
    col_description: 'الوصف',
    col_assigned_entities: 'المعينة',
    user_counts: '{{count}} مستخدم',
    application_counts: '{{count}} تطبيق',
    user_count: '{{count}} مستخدم',
    application_count: '{{count}} تطبيق',
    assign_permissions: 'تعيين الأذونات',
    create_role_title: 'إنشاء دور',
    create_role_description: 'استخدم الأدوار لتنظيم الأذونات وتعيينها للمستخدمين.',
    create_role_button: 'إنشاء دور',
    role_created: 'تم إنشاء الدور {{name}} بنجاح.',
    search: 'البحث بواسطة اسم الدور أو الوصف أو المعرف',
    placeholder_title: 'الأدوار',
    placeholder_description: 'الأدوار هي مجموعة من الأذونات التي يمكن تعيينها للمستخدمين. تأكد من إضافة الأذونات أولاً قبل إنشاء الأدوار.',
    assign_roles: 'تعيين الأدوار',
    management_api_access_notification: 'للوصول إلى واجهة برمجة تطبيقات إدارة Logto، حدد الأدوار ذات الأذونات لواجهة برمجة التطبيقات <flag/>.',
    with_management_api_access_tip: 'يتضمن هذا الدور من الجهاز إلى الجهاز أذونات واجهة برمجة تطبيقات إدارة Logto',
    role_creation_hint: 'لا يمكنك العثور على الدور المناسب؟ <a>إنشاء دور</a>',
};
export default Object.freeze(roles);
