const api_resources = {
    page_title: 'موارد واجهة برمجة التطبيقات',
    title: 'موارد واجهة برمجة التطبيقات',
    subtitle: 'تعريف واجهات برمجة التطبيقات التي يمكن لتطبيقاتك المصرح لها استخدامها',
    create: 'إنشاء مورد واجهة برمجة التطبيقات',
    api_name: 'اسم واجهة برمجة التطبيقات',
    api_name_placeholder: 'أدخل اسم واجهة برمجة التطبيقات الخاصة بك',
    api_identifier: 'معرف واجهة برمجة التطبيقات',
    api_identifier_placeholder: 'https://معرف-واجهة-التطبيق-الخاصة-بك/',
    api_identifier_tip: 'المعرف الفريد لمورد واجهة برمجة التطبيقات. يجب أن يكون عنوان URI مطلقًا ولا يحتوي على جزء العنوان (#). يساوي <a>معلمة المورد</a> في OAuth 2.0.',
    default_api: 'واجهة برمجة التطبيقات الافتراضية',
    default_api_label: 'يمكن تعيين واجهة برمجة التطبيقات الافتراضية صفر أو واحدة فقط لكل مستأجر.\nعند تعيين واجهة برمجة التطبيقات الافتراضية، يمكن تجاهل معلمة المورد في طلب المصادقة. ستستخدم عمليات تبادل الرموز المميزة لاحقًا تلك الواجهة برمجة التطبيقات كجمهور افتراضي، مما يؤدي إلى إصدار JWTs. <a>تعرف على المزيد</a>',
    api_resource_created: 'تم إنشاء مورد واجهة برمجة التطبيقات {{name}} بنجاح',
    invalid_resource_indicator_format: 'يجب أن يكون مؤشر واجهة البرمجة معرفًا صحيحًا لعنوان URI.',
};
export default Object.freeze(api_resources);
