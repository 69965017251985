const tabs = {
    get_started: 'البدء',
    dashboard: 'لوحة القيادة',
    applications: 'التطبيقات',
    api_resources: 'موارد API',
    sign_in_experience: 'تجربة تسجيل الدخول',
    connectors: 'الموصلات',
    enterprise_sso: 'SSO المؤسسات',
    webhooks: 'Webhooks',
    organizations: 'المؤسسات',
    users: 'إدارة المستخدمين',
    audit_logs: 'سجلات التدقيق',
    roles: 'الأدوار',
    docs: 'الوثائق',
    tenant_settings: 'إعدادات المستأجر',
    mfa: 'المصادقة ذات العوامل المتعددة',
    customize_jwt: 'تخصيص JWT',
    signing_keys: 'مفاتيح التوقيع',
    organization_template: 'قالب المؤسسة',
};
export default Object.freeze(tabs);
