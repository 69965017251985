const tenants = {
    title: 'الإعدادات',
    description: 'إدارة إعدادات المستأجر بكفاءة وتخصيص نطاقك.',
    tabs: {
        settings: 'الإعدادات',
        members: 'الأعضاء',
        domains: 'النطاقات',
        subscription: 'الخطة والفوترة',
        billing_history: 'سجل الفوترة',
    },
    settings: {
        title: 'الإعدادات',
        description: 'تعيين اسم المستأجر وعرض منطقة البيانات المستضيفة ونوع المستأجر.',
        tenant_id: 'معرف المستأجر',
        tenant_name: 'اسم المستأجر',
        tenant_region: 'منطقة البيانات',
        tenant_region_description: 'الموقع الفعلي الذي يتم استضافة موارد المستأجر الخاصة بك (المستخدمين ، التطبيقات ، إلخ). لا يمكن تغييره بعد الإنشاء.',
        tenant_region_tip: 'موارد المستأجر الخاصة بك مستضافة في {{region}}. <a>تعرف على المزيد</a>',
        environment_tag_development: 'تطوير',
        environment_tag_production: 'إنتاج',
        tenant_type: 'نوع المستأجر',
        development_description: 'للاختبار فقط ويجب ألا يستخدم في الإنتاج. لا يلزم اشتراك. لديه جميع الميزات المهنية ولكن لديه قيود مثل لافتة تسجيل الدخول. <a>تعرف على المزيد</a>',
        production_description: 'مخصص للتطبيقات التي يستخدمها المستخدمون النهائيون وقد تتطلب اشتراكًا مدفوعًا. <a>تعرف على المزيد</a>',
        tenant_info_saved: 'تم حفظ معلومات المستأجر بنجاح.',
    },
    full_env_tag: {
        development: 'تطوير',
        production: 'إنتاج',
    },
    deletion_card: {
        title: 'حذف',
        tenant_deletion: 'حذف المستأجر',
        tenant_deletion_description: 'سيؤدي حذف المستأجر إلى إزالة جميع البيانات والتكوين المرتبطة بشكل دائم. يرجى الاستمرار بحذر.',
        tenant_deletion_button: 'حذف المستأجر',
    },
    leave_tenant_card: {
        title: 'مغادرة',
        leave_tenant: 'مغادرة المستأجر',
        leave_tenant_description: 'سيظل أي موارد في المستأجر ولكن لن يكون لديك الوصول إلى هذا المستأجر بعد الآن.',
        last_admin_note: 'لمغادرة هذا المستأجر ، تأكد من وجود عضو آخر لديه دور المشرف.',
    },
    create_modal: {
        title: 'إنشاء مستأجر',
        subtitle: 'إنشاء مستأجر جديد يحتوي على موارد ومستخدمين معزولين.',
        tenant_usage_purpose: 'ماذا تريد استخدام هذا المستأجر ل؟',
        development_description: 'للاختبار فقط ويجب ألا يستخدم في الإنتاج. لا يلزم اشتراك.',
        development_hint: 'لديه جميع الميزات المهنية ولكن لديه قيود مثل لافتة تسجيل الدخول.',
        production_description: 'للاستخدام من قبل المستخدمين النهائيين وقد يتطلب اشتراكًا مدفوعًا.',
        available_plan: 'الخطة المتاحة:',
        create_button: 'إنشاء مستأجر',
        tenant_name_placeholder: 'مستأجري',
        tenant_created: 'تم إنشاء المستأجر بنجاح.',
        invitation_failed: 'فشل إرسال بعض الدعوات. يرجى المحاولة مرة أخرى في الإعدادات -> الأعضاء لاحقًا.',
        tenant_type_description: 'لا يمكن تغييره بعد الإنشاء.',
    },
    dev_tenant_migration: {
        title: 'يمكنك الآن تجربة ميزاتنا المهنية مجانًا عن طريق إنشاء "مستأجر تطوير جديد"!',
        affect_title: 'كيف يؤثر هذا عليك؟',
        hint_1: 'نحن نستبدل <strong>علامات البيئة</strong> القديمة بنوعين جديدين من المستأجر: <strong>"تطوير"</strong> و <strong>"إنتاج"</strong>.',
        hint_2: 'لضمان انتقال سلس ووظائف غير منقطعة ، سيتم رفع جميع المستأجرين الذين تم إنشاؤهم في وقت مبكر إلى نوع المستأجر <strong>الإنتاج</strong> مع اشتراكك السابق.',
        hint_3: 'لا تقلق ، ستظل جميع الإعدادات الأخرى كما هي.',
        about_tenant_type: 'حول نوع المستأجر',
    },
    delete_modal: {
        title: 'حذف المستأجر',
        description_line1: 'هل أنت متأكد أنك تريد حذف المستأجر "<span>{{name}}</span>" مع علامة اللاحقة البيئية "<span>{{tag}}</span>"؟ لا يمكن التراجع عن هذا الإجراء ، وسيؤدي إلى حذف جميع بياناتك ومعلومات المستأجر بشكل دائم.',
        description_line2: 'قبل حذف المستأجر ، ربما يمكننا مساعدتك. <span><a>اتصل بنا عبر البريد الإلكتروني</a></span>',
        description_line3: 'إذا كنت ترغب في المتابعة ، يرجى إدخال اسم المستأجر "<span>{{name}}</span>" للتأكيد.',
        delete_button: 'حذف بشكل دائم',
        cannot_delete_title: 'لا يمكن حذف هذا المستأجر',
        cannot_delete_description: 'عذرًا ، لا يمكنك حذف هذا المستأجر الآن. يرجى التأكد من أنك على خطة مجانية وقد دفعت جميع الفواتير المستحقة.',
    },
    leave_tenant_modal: {
        description: 'هل أنت متأكد أنك تريد مغادرة هذا المستأجر؟',
        leave_button: 'مغادرة',
    },
    tenant_landing_page: {
        title: 'لم تقم بإنشاء مستأجر بعد',
        description: 'لبدء تكوين مشروعك باستخدام Logto ، يرجى إنشاء مستأجر جديد. إذا كنت بحاجة إلى تسجيل الخروج أو حذف حسابك ، فقط انقر فوق زر الصورة الرمزية في الزاوية اليمنى العلوية.',
        create_tenant_button: 'إنشاء مستأجر',
    },
    status: {
        mau_exceeded: 'تجاوز MAU',
        suspended: 'معلق',
        overdue: 'متأخر عن الدفع',
    },
    tenant_suspended_page: {
        title: 'تم تعليق المستأجر. اتصل بنا لاستعادة الوصول.',
        description_1: 'نأسف بشدة لإبلاغك بأن حساب المستأجر الخاص بك تم تعليقه مؤقتًا بسبب الاستخدام غير السليم ، بما في ذلك تجاوز حدود MAU ، وتأخر الدفع ، أو إجراءات غير مصرح بها أخرى.',
        description_2: 'إذا كنت تحتاج إلى توضيحات إضافية أو لديك أي مخاوف أو ترغب في استعادة الوظائف الكاملة وإلغاء حظر المستأجرين الخاص بك ، فيرجى عدم التردد في الاتصال بنا فورًا.',
    },
    production_tenant_notification: {
        text: 'أنت في مستأجر تطوير للاختبار المجاني. قم بإنشاء مستأجر إنتاج للبدء.',
        action: 'إنشاء مستأجر',
    },
};
export default Object.freeze(tenants);
