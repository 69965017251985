const permissions = {
    search_placeholder: 'البحث حسب اسم الواجهة البرمجية أو اسم الصلاحية',
    search_placeholder_without_api: 'البحث حسب اسم الصلاحية',
    name_column: 'الصلاحية',
    description_column: 'الوصف',
    api_column: 'واجهة برمجية',
    placeholder_title: 'الصلاحية',
    placeholder_description: 'الصلاحية تشير إلى التصريح بالوصول إلى مورد (نسميه مورد واجهة برمجية).',
    edit: 'تعديل الصلاحية',
    delete: 'حذف الصلاحية',
    remove: 'إزالة الصلاحية',
    edit_title: 'تعديل صلاحية واجهة برمجية',
};
export default Object.freeze(permissions);
