const invitation = {
    find_your_tenants: 'ابحث عن المستأجرين الخاصين بك',
    find_tenants_description: 'قد يكون عنوان بريدك الإلكتروني مسجل بالفعل في عدة مستأجرين. يمكنك اختيار الانضمام إلى المستأجرين الموجودين أو الاستمرار في إنشاء مستأجر جديد.',
    create_new_tenant: 'إنشاء مستأجر جديد',
    email_not_match_title: 'أنت مسجل حاليًا بواسطة\n{{email}}',
    email_not_match_description: 'يرجى تسجيل الدخول باستخدام الحساب الصحيح لقبول الدعوة وأن تصبح عضوًا في المنظمة.',
    switch_account: 'تسجيل الدخول باستخدام حساب آخر',
    invalid_invitation_status: 'دعوة غير صالحة. يرجى الاتصال بالمسؤول والمحاولة مرة أخرى.',
    invitation_not_found: 'لم يتم العثور على الدعوة. يرجى الاتصال بالمسؤول.',
};
export default Object.freeze(invitation);
