const demo_app = {
    title: 'لقد قمت بتسجيل الدخول بنجاح في المعاينة المباشرة!',
    subtitle: 'إليك معلومات المستخدم الخاصة بك:',
    username: 'اسم المستخدم: ',
    user_id: 'معرف المستخدم: ',
    sign_out: 'تسجيل الخروج من المعاينة المباشرة',
    continue_explore: 'أو استمر في استكشاف',
    customize_sign_in_experience: 'تخصيص تجربة تسجيل الدخول',
    enable_passwordless: 'تمكين تسجيل الدخول بدون كلمة مرور',
    add_social_connector: 'إضافة موصل اجتماعي',
};
export default Object.freeze(demo_app);
