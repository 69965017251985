const signing_keys = {
    title: 'مفاتيح التوقيع',
    description: 'إدارة آمنة لمفاتيح التوقيع المستخدمة في تطبيقاتك.',
    private_key: 'مفاتيح OIDC الخاصة',
    private_keys_description: 'تستخدم مفاتيح OIDC الخاصة لتوقيع رموز JWT.',
    cookie_key: 'مفاتيح ملف تعريف الارتباط OIDC',
    cookie_keys_description: 'تستخدم مفاتيح ملف تعريف الارتباط OIDC لتوقيع ملفات تعريف الارتباط.',
    private_keys_in_use: 'المفاتيح الخاصة المستخدمة',
    cookie_keys_in_use: 'مفاتيح ملف تعريف الارتباط المستخدمة',
    rotate_private_keys: 'تدوير المفاتيح الخاصة',
    rotate_cookie_keys: 'تدوير مفاتيح ملف تعريف الارتباط',
    rotate_private_keys_description: 'سيؤدي هذا الإجراء إلى إنشاء مفتاح توقيع خاص جديد وتدوير المفتاح الحالي وإزالة المفتاح السابق. ستظل رموز JWT الموقعة بالمفتاح الحالي صالحة حتى الحذف أو جولة أخرى من التدوير.',
    rotate_cookie_keys_description: 'سيؤدي هذا الإجراء إلى إنشاء مفتاح ملف تعريف الارتباط جديد وتدوير المفتاح الحالي وإزالة المفتاح السابق. ستظل ملفات تعريف الارتباط الحالية صالحة حتى الحذف أو جولة أخرى من التدوير.',
    select_private_key_algorithm: 'حدد خوارزمية مفتاح التوقيع للمفتاح الخاص الجديد',
    rotate_button: 'تدوير',
    table_column: {
        id: 'المعرف',
        status: 'الحالة',
        algorithm: 'خوارزمية مفتاح التوقيع',
    },
    status: {
        current: 'الحالي',
        previous: 'السابق',
    },
    reminder: {
        rotate_private_key: 'هل أنت متأكد أنك تريد تدوير <strong>مفاتيح OIDC الخاصة</strong>؟ سيتم توقيع رموز JWT الصادرة الجديدة بواسطة المفتاح الجديد. ستظل رموز JWT الحالية صالحة حتى تقوم بتدويرها مرة أخرى أو حذفها.',
        rotate_cookie_key: 'هل أنت متأكد أنك تريد تدوير <strong>مفاتيح ملف تعريف الارتباط OIDC</strong>؟ سيتم توقيع ملفات تعريف الارتباط الجديدة التي تم إنشاؤها في جلسات تسجيل الدخول بواسطة المفتاح الجديد. ستظل ملفات تعريف الارتباط الحالية صالحة حتى تقوم بتدويرها مرة أخرى أو حذفها.',
        delete_private_key: 'هل أنت متأكد أنك تريد حذف <strong>مفتاح OIDC الخاص</strong>؟ لن تكون رموز JWT الحالية الموقعة بمفتاح التوقيع الخاص هذا صالحة بعد الآن.',
        delete_cookie_key: 'هل أنت متأكد أنك تريد حذف <strong>مفتاح ملف تعريف الارتباط OIDC</strong>؟ لن تكون جلسات تسجيل الدخول السابقة التي تحمل ملفات تعريف الارتباط الموقعة بمفتاح ملف تعريف الارتباط هذا صالحة بعد الآن. سيتطلب إعادة المصادقة لهؤلاء المستخدمين.',
    },
    messages: {
        rotate_key_success: 'تم تدوير مفاتيح التوقيع بنجاح.',
        delete_key_success: 'تم حذف المفتاح بنجاح.',
    },
};
export default Object.freeze(signing_keys);
