const menu = {
    profile: 'الملف الشخصي',
    language: 'اللغة',
    appearance: {
        label: 'المظهر',
        light: 'وضع النهار',
        dark: 'وضع الليل',
        system: 'مزامنة مع النظام',
    },
    sign_out: 'تسجيل الخروج',
};
export default Object.freeze(menu);
